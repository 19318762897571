import * as React from 'react'
import "tailwindcss/base.css"
import "tailwindcss/components.css"
import "tailwindcss/screens.css"
import "tailwindcss/utilities.css"
import "tailwindcss/tailwind.css"
import "tailwindcss/variants.css"

import { Helmet } from 'react-helmet'
import { CookiesProvider } from 'react-cookie'

import Home from './home'
import favicon from '../images/favicon/sekyee_fav_16.png'


// markup
const IndexPage = () => {
  return (
    <CookiesProvider>

        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        <Home />

    </CookiesProvider>
  )
}

export default IndexPage
